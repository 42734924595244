

































import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import {
  convertValIfImperial,
  convertUnitIfImperial
} from '@/store/utils/index'
import { LFeatureGroup, LCircle, LMarker, LIcon } from 'vue2-leaflet'
import { TLocationPoint } from '../types'

export default Vue.extend({
  name: 'RadiatingCircles',
  components: {
    LFeatureGroup,
    LCircle,
    LMarker,
    LIcon
  },
  data: () => ({
    distances: [100, 250, 500, 1000, 2500, 5000, 10000],
    renderingTrigger: true,
    minZoom: [17, 15, 13, 12, 11, 10, 9]
  }),
  computed: {
    ...mapGetters('sites', ['activeSiteId']),
    ...mapGetters('system', ['systemSetting']),
    ...mapState(['radiatingCircle']),
    primaryColor() {
      return this.$vuetify.theme.primary
    },
    originCoordinates(): TLocationPoint {
      return this.radiatingCircle.coordinates
    },
    latLng(): TLocationPoint {
      return this.activeSiteId && this.originCoordinates
    },
    labelLatlng(): TLocationPoint[] {
      this.renderingTrigger
      return this.distances.map(
        (_, i) =>
          (this.$refs[`rc-${i}`] &&
            this.$refs[`rc-${i}`][0] && [
              this.$refs[`rc-${i}`][0].mapObject.getBounds()._northEast.lat,
              this.latLng[1]
            ]) ||
          null
      )
    },
    zoomLevel(): number {
      this.renderingTrigger
      return (
        this.$refs[`rc-0`] &&
        this.$refs[`rc-0`][0] &&
        this.$refs[`rc-0`][0].mapObject._map.getZoom()
      )
    }
  },
  methods: {
    convertValIfImperial: convertValIfImperial,
    convertUnitIfImperial: convertUnitIfImperial,

    forceLabelUpdate(): void {
      this.$nextTick(() => {
        this.renderingTrigger = !this.renderingTrigger
      })
    }
  },
  watch: {
    latLng(): void {
      this.forceLabelUpdate()
    }
  },
  mounted(): void {
    this.forceLabelUpdate()
  }
})
